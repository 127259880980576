export const environment = {
  production: true,
  demo: false,
  bluectrlapi: 'https://api.cfe-computing.com',
  ModuleWifiSurFix: 'BLUELINE_',
  DefaultModuleIP: '192.168.1.10',
  DefaultModuleApi: 'https://192.168.1.10:8443/',
  DefaultModuleWifiKey: 'BLUELINE_FTW',
  ClusterWifiSurFix: 'BLUECTRL_',
  DefaultClusterWifiKey: 'SdHnRPCM8962c3JY',
  GrafanaClusterLogUrl: 'https://monitor.bluectrl.app/d/Sf1ZRVS4z/bluectrl-cluster-logging?orgId=1&var-clusterid='
};
